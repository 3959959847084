import React from "react";
import { Link } from "react-router-dom";
import TeamMemberOneImg1 from "../../assets/images/team/team-member-one-1.jpg";
import TeamMemberOneImg2 from "../../assets/images/team/team-member-one-2.jpg";
import TeamMemberOneImg3 from "../../assets/images/team/team-member-one-3.jpg";
import TeamMemberOneImg4 from "../../assets/images/team/team-member-one-4.jpg";
import TeamMemberOneImg5 from "../../assets/images/team/team-member-one-5.jpg";
import TeamMemberOneImg6 from "../../assets/images/team/team-member-one-6.jpg";

function TeamMemberOne() {
  const teamMembers = [
    { name: "Meaad Salim", role: "Producer", image: TeamMemberOneImg1 },
    { name: "Hani Sardar", role: "CEO", image: TeamMemberOneImg2 },
    { name: "Majid", role: "Graphic Designer", image: TeamMemberOneImg3 },
    { name: "Sami Khudeeda", role: "Executive Director", image: TeamMemberOneImg4 },
    { name: "Omed Hseen", role: "Gaffer", image: TeamMemberOneImg5 },
    { name: "Maya Shadi", role: "Model", image: TeamMemberOneImg6 },
  ];

  return (
    <div>
      <section className="team-member-one">
        <div className="container">
          <div className="team-member-one-title-box">
            <div className="section-four-title">
              <div
                className="section-four-title-title text-center wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="100ms"
              >
                <h2>Meet Our Team Members</h2>
              </div>
            </div>
            <div
              className="section-four-details text-center wow fadeInUp animated"
              data-wow-duration="1500ms"
              data-wow-delay="300ms"
            >
              <p>
                Digital marketing &amp; advertising approach focuses on capturing
                the essential
              </p>
            </div>
          </div>
          <div className="row">
            {teamMembers.map((member, index) => (
              <div
                key={index}
                className="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              >
                <div
                  className="team-member-one-single-box wow fadeInUp animated"
                  data-wow-duration="1500ms"
                  data-wow-delay={`${100 * index}ms`}
                >
                  <div className="team-member-one-single-img">
                    <div className="tiles">
                      <img
                        className="tile object-cover"
                        data-scale="1.15"
                        src={member.image}
                        alt={`team${index + 1}`}
                      />
                    </div>
                    {/* <div className="team-member-one-single-img-tag">
                      <i className="icon-plus-2" />
                      <ul className="team-member-social">
                        <li>
                          <Link to="/#">
                            <i className="icon-facebook-f" />
                          </Link>
                        </li>
                        <li>
                          <Link to="/#">
                            <i className="icon-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="/#">
                            <i className="icon-instagram" />
                          </Link>
                        </li>
                        <li>
                          <Link to="/#">
                            <i className="icon-linkedin-in" />
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <h4 className="team-member-one-single-title">
                    <Link to="/team-member">{member.name}</Link>
                  </h4>
                  <p className="team-member-one-single-detail">{member.role}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default TeamMemberOne;
