import React from "react";
import { Link } from "react-router-dom";
import caseThreeImg1 from "../../assets/images/resources/case-three-1.png";
import caseThreeImg2 from "../../assets/images/resources/case-three-2.png";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomSlider from "./CustomSlider";

const Caseslider = {
  loop: true,
  autoplayspeed: 2000,
  slidesPerView: 5,
  slidestoscroll: 5,
  autoplay: true,
  easing: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesPerView: 2,
        slidestoscroll: 3,
        arrows: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesPerView: 2,
        slidestoscroll: 3,
      },
    },
    {
      breakpoint: 740,
      settings: {
        slidesPerView: 1,
        slidestoscroll: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesPerView: 1,
        slidestoscroll: 3,
      },
    },
  ],
};


function CaseTwo() {
  return (
    <div className="">
      <section className="case-two">
        <div className="container">
          <div className="case-two-title-box">
            <div className="section-five-title">
              <div
                className="section-five-title-text text-center wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="100ms"
              >
                <p>Latest Projects</p>
              </div>
              <div
                className="section-five-title-title text-center wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <h2>Explore Our Recent Success Stories and Results</h2>
              </div>
            </div>
          </div>
          <CustomSlider />
          {/* <div className="case-two-wrapper">
            <div
              className="case-two-single-box wow fadeInUp animated "
              data-wow-duration="1500ms"
              data-wow-delay="100ms"
            >
              <img src={caseThreeImg1} alt="case-three-1" className="case-two-img"/>
              <div className="case-two-single-detail-box">
                <span>Social media management</span>
                <h4>Visa Line</h4>
                <p>Content creation and management of social platforms</p>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default CaseTwo;
