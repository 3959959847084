import React from 'react';
// import { Link } from 'react-router-dom';
import Service1 from '../../assets/images/resources/service-one-img-1.png';
import Service2 from '../../assets/images/resources/service-one-img-2.png';
import Service3 from '../../assets/images/resources/service-one-img-3.png';
import Service4 from '../../assets/images/resources/service-one-img-4.png';
import Service5 from '../../assets/images/resources/service-one-img-5.png';
import Service6 from '../../assets/images/resources/service-one-img-6.png';

const serviceData = [
  {
    imgSrc: Service1,
    title: 'Boost Engagement with Targeted Social Media Strategies',
  },
  {
    imgSrc: Service2,
    title: 'Content Creation and Audience Insights',
  },
  {
    imgSrc: Service3,
    title: 'Social Media Management and Brand Growth',
  },
  {
    imgSrc: Service4,
    title: "Optimize Your Brand's Online Presence",
  },
  {
    imgSrc: Service5,
    title: 'Tailored Social Media Strategies for e-Commerce',
  },
  {
    imgSrc: Service6,
    title: 'Analytics and Performance Tracking for Continuous Improvement',
  },
];

function ServiceOne() {
  return (
    <div>
      <section className="service-one">
        <div className="service-one-shape-1" />
        <div className="service-one-shape-2" />
        <div className="service-one-shape-3" />
        <div className="service-one-shape-4" />
        <div className="container">
          <div
            className="section-title text-center wow fadeInUp animated"
            data-wow-duration="1500ms"
            data-wow-delay="100ms"
          >
            <h2>Effective Social Media Strategies for Unmatched Engagement</h2>
          </div>
          <div
            className="section-details text-center wow fadeInUp animated"
            data-wow-duration="1500ms"
            data-wow-delay="300ms"
          >
            <p>
              Our approach focuses on driving real results and connecting your brand with the right audience.
            </p>
          </div>
          <div className="service-one-info">
            <div className="row">
              {serviceData.map((service, index) => (
                <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <div
                    className={`service-one-box service-one-box-${index + 1} wow fadeInUp animated`}
                    data-wow-duration="1500ms"
                    data-wow-delay={`${100 * (index + 1)}ms`}
                  >
                    {/* <div className="service-one-img-box">
                      <img src={service.imgSrc} alt={`service-one-img-${index + 1}`} />
                    </div> */}
                    <div className="service-one-title-box">
                      <h3>{service.title}</h3>
                    </div>
                    {/* <div className="service-one-btn-box">
                      <Link to="/services-details" className="read-more-btn">
                        Read More
                        <i className="icon-right-arrow" />
                      </Link>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServiceOne;
