import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom'
import WOW from 'wowjs';
import logoTwoImg from "../../assets/images/resources/logo-two.png"
import LogoPng from "../../assets/images/resources/logo.png";
import sectionFiveTitleIcon from "../../assets/images/shapes/section-five-title-text-icon.png";


function Main() {

  const [showHome, setshowHome] = useState(false);
  const [showPage, setshowpage] = useState(false);
  const [showServices, setshowservices] = useState(false);
  const [showShop, setshowShop] = useState(false);
  const [showBlog, setshowBlog] = useState(false);

  const location = useLocation()
  const path = location.pathname
  const [menu, setmenu] = useState({})
  const activeMenu = () => {
    if (path === "/" || path === "/") {
      setmenu({ home: true })
    } else if (path === "/page" || path === "/page") {
      setmenu({ Page: true })
    } else if (path === "/services" || path === "/services") {
      setmenu({ services: true })
    } else if (path === "/shop" || path === "/shop") {
      setmenu({ shop: true })
    } else if (path === "/blog" || path === "/blog") {
      setmenu({ blog: true })
    }
  }


  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  useEffect(() => {
    window.scroll(0, 0)
    new WOW.WOW({
      live: false
    }).init();
    activeMenu()
  }, [path]);
  const issticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 141 ? setIsSticky(true) : setIsSticky(false);
  }

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <div>
      <header className={`main-header main-header-five ${isSticky ? "sticky-header--cloned sticky-fixed" : "sticky-header"}`}>
        <div className="container-fluid">
          <nav className="main-menu main-menu-five">
            <div className="main-menu-logo">
              <Link to="/">
                <img
                  src={logoTwoImg}
                  alt="header-logo"
                />
              </Link>
            </div>
            <ul className="main-menu-list main-menu-five-list">
              {/* <li className="menu-item-children">
                <Link to="#">Home</Link>
                <i className="icon-angle-down" />
                <ul>
                  <li>
                    <Link to="/">Home One</Link>
                  </li>
                  <li>
                    <Link to="/hometwo">Home Two</Link>
                  </li>
                  <li>
                    <Link to="/homethree">Home Three</Link>
                  </li>
                  <li>
                    <Link to="/homefour">Home Four</Link>
                  </li>
                  <li>
                    <Link to="/homefive">Home Five</Link>
                  </li>
                </ul>
              </li> */}
              <li>
                <Link to="/">Home</Link>
              </li>
              {/* <li className="menu-item-children">
                <Link to="#">Page</Link>
                <i className="icon-angle-down" />
                <ul>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li className="menu-item-children">
                    <Link to="#">
                      Projects
                      <i className="fa fa-angle-down" />
                    </Link>
                    <ul className="sub-menu-inner">
                      <li>
                        <Link to="/projects">Projects</Link>
                      </li>
                      <li>
                        <Link to="/project-details">Project Details</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/team-member">Team</Link>
                  </li>
                  <li>
                    <Link to="/package">Package</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQs</Link>
                  </li>
                </ul>
              </li> */}
              <li className="menu-item-children">
                <Link to="/service">Services</Link>
                {/* <i className="icon-angle-down" />
                <ul>
                  <li>
                    <Link to="/service">Popular Services</Link>
                  </li>
                  <li>
                    <Link to="/services-details">SEO Services</Link>
                  </li>
                  <li>
                    <Link to="/digital-market">Digital Marketing</Link>
                  </li>
                </ul> */}
              </li>
              {/* <li className="menu-item-children">
                <Link to="#">Shop</Link>
                <i className="icon-angle-down" />
                <ul className="sub-menu-inner">
                  <li>
                    <Link to="/shop">Shop Page</Link>
                  </li>
                  <li>
                    <Link to="/shop-details">Product Deatils</Link>
                  </li>
                  <li>
                    <Link to="/cart">Cart</Link>
                  </li>
                  <li>
                    <Link to="/checkout">Checkout</Link>
                  </li>
                </ul>
              </li> */}
              {/* <li className="menu-item-children">
                <Link to="#">Blog</Link>
                <i className="icon-angle-down" />
                <ul>
                  <li>
                    <Link to="/blog">Blog List</Link>
                  </li>
                  <li>
                    <Link to="/blog-details">Blog Details</Link>
                  </li>
                </ul>
              </li> */}
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/projects">Projects</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <div className="header-right-end" onClick={toggleMobileMenu}>
              <span className="line-1" />
              <span className="line-2" />
              <span className="line-3" />
            </div>
          </nav>
        </div>
      </header>

      {mobileMenuOpen &&
        <div className={`mobile-nav-wrapper ${mobileMenuOpen && "expanded"}`}>
          <div className="mobile-nav-overlay mobile-nav-toggler" />
          <div className="mobile-nav-content">
            <Link
              to="#"
              onClick={() => setMobileMenuOpen(false)}
              className="mobile-nav-close mobile-nav-toggler"
            >
              <span />
              <span />
            </Link>
            <div className="logo-box">
              <Link to="/">
                <img src={LogoPng} alt="logo" />
              </Link>
            </div>

            <div className="mobile-nav-container">
              <ul className="mobile-menu-list">
                {/* <li className="menu-item-children">
                <Link to="#">Home</Link>
                <i className="icon-angle-down" />
                <ul>
                  <li>
                    <Link to="/">Home One</Link>
                  </li>
                  <li>
                    <Link to="/hometwo">Home Two</Link>
                  </li>
                  <li>
                    <Link to="/homethree">Home Three</Link>
                  </li>
                  <li>
                    <Link to="/homefour">Home Four</Link>
                  </li>
                  <li>
                    <Link to="/homefive">Home Five</Link>
                  </li>
                </ul>
              </li> */}
                <li>
                  <Link to="/">Home</Link>
                </li>
                {/* <li className="menu-item-children">
                <Link to="#">Page</Link>
                <i className="icon-angle-down" />
                <ul>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li className="menu-item-children">
                    <Link to="#">
                      Projects
                      <i className="fa fa-angle-down" />
                    </Link>
                    <ul className="sub-menu-inner">
                      <li>
                        <Link to="/projects">Projects</Link>
                      </li>
                      <li>
                        <Link to="/project-details">Project Details</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/team-member">Team</Link>
                  </li>
                  <li>
                    <Link to="/package">Package</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQs</Link>
                  </li>
                </ul>
              </li> */}
                <li className="menu-item-children">
                  <Link to="/service">Services</Link>
                  {/* <i className="icon-angle-down" />
                <ul>
                  <li>
                    <Link to="/service">Popular Services</Link>
                  </li>
                  <li>
                    <Link to="/services-details">SEO Services</Link>
                  </li>
                  <li>
                    <Link to="/digital-market">Digital Marketing</Link>
                  </li>
                </ul> */}
                </li>
                {/* <li className="menu-item-children">
                <Link to="#">Shop</Link>
                <i className="icon-angle-down" />
                <ul className="sub-menu-inner">
                  <li>
                    <Link to="/shop">Shop Page</Link>
                  </li>
                  <li>
                    <Link to="/shop-details">Product Deatils</Link>
                  </li>
                  <li>
                    <Link to="/cart">Cart</Link>
                  </li>
                  <li>
                    <Link to="/checkout">Checkout</Link>
                  </li>
                </ul>
              </li> */}
                {/* <li className="menu-item-children">
                <Link to="#">Blog</Link>
                <i className="icon-angle-down" />
                <ul>
                  <li>
                    <Link to="/blog">Blog List</Link>
                  </li>
                  <li>
                    <Link to="/blog-details">Blog Details</Link>
                  </li>
                </ul>
              </li> */}
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/projects">Projects</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>

            <ul className="mobile-nav-contact list-unstyled">
              <li>
                <i className="icon-call-icon" />
                <Link
                  to="/tel:+9647511401195"
                  className="footer-widget-number"
                >
                  +964 751 140 11 95
                </Link>
              </li>
              {/* <li>
                <i className="fa fa-envelope" />
                <Link to="/mailto:info@zoso.com">info@zoso.com</Link>
              </li> */}
            </ul>
            <ul className="mobile-nav-social">
              <li>
                <Link to="https://www.instagram.com/openline.co?igsh=bTA3dm8wb2pnZWVs">
                  <i className="icon-instagram" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      }

    </div>
  );
}

export default Main;
