import React from "react";
import { Link } from "react-router-dom";

function Main({ subtitle, title }) {
  return (
    <div>
      <section className="page-header">
        <div className="page-header-shape-1" />
        <div className="page-header-shape-2" />
        <div className="page-header-shape-3" />
        <div className="page-header-shape-4" />
        <div className="container">
          <div className="page-header-inner text-center">
            <h2
              className="swift-up-text wow fadeInUp animated"
              data-wow-duration="1500ms"
              data-wow-delay="100ms"
            >
              {" "}
             {subtitle}
            </h2>
            <ul
              className="page-header-inner-title list-unstyled wow fadeInUp animated"
              data-wow-duration="1500ms"
              data-wow-delay="300ms"
            >
              <li className="arrow-right">
                <Link to="/">{title}</Link>
              </li>
              <li>
                <h4>{subtitle}</h4>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Main;
