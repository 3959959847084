import React from "react";
import HeaderFive from "../HeaderFive/Main";
import Footer from "../FooterOne/Main";
import Breadcrumb from "../Breadcrumb/Main";
import PartnerFive from "../Menabout/PartnerFive";
// import CtaFive from "../Menabout/CtaFive";
import JoinusOne from "../Menabout/JoinusOne";
import TeamMemberOne from "../Menabout/TeamMemberOne";
import VideoTwo from "../Menabout/VideoTwo";
import AboutPage from "../Menabout/AboutPage";
import CtaFour from "../Menhomefive/CtaFour";

function Main() {
  return (
    <div>
      <HeaderFive />
      <div className="main-wrapper">
        <div className="data-scroll">
          <div className="page-wrapper">
            <div className="page-wrapper-bg-1" />
            <div className="page-wrapper-bg-2" />
            <main>
              <Breadcrumb title="Home" subtitle="About Open Line" />
              <AboutPage />
              {/* <VideoTwo /> */}
              <TeamMemberOne />
              {/* <JoinusOne /> */}
              <CtaFour />
              {/* <PartnerFive /> */}
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
