import React, { useState, createRef } from 'react'
import Projectdtls1 from '../../assets/images/shapes/testimonial-three-shape-1.png'
import Shape1 from '../../assets/images/shapes/services-details-content-shape-1.png'
import Banner from '../../assets/images/projects/visaline-banner.png'
import HeaderFive from "../../component/HeaderFive/Main";
import Footer from "../../component/FooterOne/Main";
import Breadcrumb from "../../component/Breadcrumb/Main";
import visalineImage1 from '../../assets/images/projects/visaline/visaline-1.png'
import visalineImage2 from '../../assets/images/projects/visaline/visaline-2.png'
import visalineImage3 from '../../assets/images/projects/visaline/visaline-3.png'
import visalineImage4 from '../../assets/images/projects/visaline/visaline-4.png'
import visalineImage5 from '../../assets/images/projects/visaline/visaline-5.png'
import visalineImage6 from '../../assets/images/projects/visaline/visaline-6.png'
import visalineImage7 from '../../assets/images/projects/visaline/visaline-7.png'
import visalineImage8 from '../../assets/images/projects/visaline/visaline-8.png'

import poster1 from '../../assets/images/projects/visaline/videos/1.jpg'
import video1 from '../../assets/images/projects/visaline/videos/1.mp4'

import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'

import "../../assets/css/video-gallery.css"

const images = [
    visalineImage1,
    visalineImage2,
    visalineImage3,
    visalineImage4,
    visalineImage5,
    visalineImage6,
    visalineImage7,
    visalineImage8,
]

const videos = [
    {
        id: 1,
        src: video1,
        poster: poster1,
    },
]

function VisaLine() {

    return (
        <div>
            <HeaderFive />
            <div className="main-wrapper">
                <div className="data-scroll">
                    <div className="page-wrapper">
                        <div className="page-wrapper-bg-3" />
                        <main className="site-main">
                            <Breadcrumb title="Home" subtitle="Visa Line" />
                            {/* Single Project Comp */}

                            <div>
                                <section className="project-details">
                                    <div className="services-details-content-shape-1">
                                        <div className="animation_image_zoom">
                                            <div className="services-details-content-shape-1-img">
                                                <img
                                                    src={Projectdtls1}
                                                    alt="testimonial-three-shape-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="services-details-content-shape-2">
                                        <div className="animation_image_zoom">
                                            <div className="services-details-content-shape-2-img">
                                                <img
                                                    src={Shape1}
                                                    alt="services-details-content-shape-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="project-details-box">
                                            <div className="project-details-title-box">
                                                <div className="section-three-title">
                                                    <div
                                                        className="project-details-title-text wow fadeInUp animated"
                                                        data-wow-duration="1500ms"
                                                        data-wow-delay="100ms"
                                                    >
                                                        <span>Social Media</span>
                                                    </div>
                                                    <div
                                                        className="section-three-title-title wow fadeInUp animated"
                                                        data-wow-duration="1500ms"
                                                        data-wow-delay="300ms"
                                                    >
                                                        <h2>Visa Line</h2>
                                                    </div>
                                                </div>
                                                <div
                                                    className="section-three-details wow fadeInUp animated"
                                                    data-wow-duration="1500ms"
                                                    data-wow-delay="500ms"
                                                >
                                                    <p>
                                                        Content creation and social media management
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="project-details-img wow fadeInUp animated"
                                                data-wow-duration="1500ms"
                                                data-wow-delay="100ms"
                                            >
                                                <img
                                                    src={Banner}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="project-details-info">
                                                <div
                                                    className="project-details-info-item wow fadeInUp animated"
                                                    data-wow-duration="1500ms"
                                                    data-wow-delay="100ms"
                                                >
                                                    <span>CLIENT</span>
                                                    <h3>Visa Line</h3>
                                                </div>
                                                <div
                                                    className="project-details-info-item wow fadeInUp animated"
                                                    data-wow-duration="1500ms"
                                                    data-wow-delay="300ms"
                                                >
                                                    <span>Category</span>
                                                    <h3>Social Media</h3>
                                                </div>
                                                <div
                                                    className="project-details-info-item wow fadeInUp animated"
                                                    data-wow-duration="1500ms"
                                                    data-wow-delay="500ms"
                                                >
                                                    <span>Location</span>
                                                    <h3>Duhok</h3>
                                                </div>
                                            </div>
                                            {/* results */}
                                            <div
                                                className="project-details-inner-title wow fadeInUp animated"
                                                data-wow-duration="1500ms"
                                                data-wow-delay="100ms"
                                            >
                                                <h2>Results</h2>
                                            </div>
                                            <div class="marquee">
                                                <div class="marquee--inner">
                                                    <span>
                                                        {images.map((image, key) => (
                                                            <div className="orb" key={key}>
                                                                <img
                                                                    src={image}
                                                                    alt="client-one-img-1"
                                                                    className=''
                                                                />
                                                            </div>
                                                        ))}
                                                    </span>
                                                    <span>
                                                        {images.map((image, key) => (
                                                            <div className="orb" key={key}>
                                                                <img
                                                                    src={image}
                                                                    alt="client-one-img-1"
                                                                    className=''
                                                                />
                                                            </div>
                                                        ))}
                                                    </span>
                                                </div>
                                            </div>

                                            {/* Videos */}
                                            <div
                                                className="project-details-inner-title wow fadeInUp animated"
                                                data-wow-duration="1500ms"
                                                data-wow-delay="100ms"
                                            >
                                                <h2>Videos</h2>
                                            </div>
                                            <div className='gallery'>
                                                {videos.map((video) => {
                                                    return (
                                                        <div key={video.id} className="video">
                                                            <div className='video-container'>
                                                                <Video
                                                                    style={{ width: '100%' }}
                                                                    controls={['PlayPause', 'Seek', 'Time', 'Volume', 'FullScreen']}
                                                                    poster={video.poster}
                                                                >
                                                                    <source src={video.src} type="video/mp4" />
                                                                </Video>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </main>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VisaLine
