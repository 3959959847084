import React from 'react'
import { Link } from 'react-router-dom';
import aboutThreeLeftShapeImg1 from "../../assets/images/shapes/about-three-left-box-shape-1.png"
import aboutThreeLeftShapeImg2 from "../../assets/images/shapes/about-three-left-box-shape-2.png"
import TestimonialFOurdetailImg1 from "../../assets/images/testimonial/testimonial-four-detail-img-one.jpg"
import TestimonialFOurdetailImg2 from "../../assets/images/testimonial/testimonial-four-detail-img-two.jpg"

function CtaFour() {
  return (
    <div>
      <section className="cta-four">
        <div
          className="cta-four-shape-1 wow fadeInLeft animated"
          data-wow-duration="1500ms"
          data-wow-delay="300ms"
        >
          <div className="animation_image_zoom">
            <div className="cta-four-shape-img-one">
              <img
                src={aboutThreeLeftShapeImg2}
                alt="about-three-left-box-shape-2"
              />
            </div>
          </div>
        </div>
        <div
          className="cta-four-shape-2 wow fadeInRight animated"
          data-wow-duration="1500ms"
          data-wow-delay="300ms"
        >
          <div className="animation_image_zoom">
            <div className="cta-four-shape-img-two">
              <img
                src={aboutThreeLeftShapeImg1}
                alt="about-three-left-box-shape-1"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="cta-four-detail-box">
            <div
              className="cta-four-detail-box-shape-1 wow fadeInLeft animated"
              data-wow-duration="1500ms"
              data-wow-delay="100ms"
            >
              <div className="animation_image_zoom">
                <div className="cta-four-detail-box-shape-img-one">
                  <img
                    src={TestimonialFOurdetailImg1}
                    alt="testimonial-four-detail-img-one"
                  />
                </div>
              </div>
            </div>
            <div
              className="cta-four-detail-box-shape-2 wow fadeInRight animated"
              data-wow-duration="1500ms"
              data-wow-delay="100ms"
            >
              <div className="animation_image_zoom">
                <div className="cta-four-detail-box-shape-img-two">
                  <img
                    src={TestimonialFOurdetailImg2}
                    alt="testimonial-four-detail-img-two"
                  />
                </div>
              </div>
            </div>
            <div className="cta-four-detail-box-shape-3" />
            <div className="cta-four-detail-box-shape-4" />
            <div className="section-five-title">
              <div
                className="section-five-title-title text-center wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="100ms"
              >
                <h2>Ready to Boost your Digital product marketing ?</h2>
              </div>
              <div
                className="section-five-details text-center wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <p>Approach focuses on capturing the essential</p>
              </div>
            </div>
            <div
              className="cta-four-btn text-center wow fadeInUp animated"
              data-wow-duration="1500ms"
              data-wow-delay="500ms"
            >
              <Link to="/contact" className="main-btn-five thm-btn">
                Get Started
                <i className="icon-right-arrow" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CtaFour
