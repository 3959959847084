import { useState } from "react";

function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const [response, setResponse] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send form data to PHP backend via POST
    try {
      const res = await fetch("/send_mail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData), // Send form data as JSON
      });

      const result = await res.text(); // Get response from PHP
      setResponse(result);
      console.log(result)
    } catch (error) {
      console.error("Error:", error);
      setResponse("Failed to send email.");
    }
  };

  return (
    <div>
      <section className="contact-page">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div
                className="contact-page-left wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="100ms"
              >
                <div className="section-main-title">
                  <h2>Don’t Heisted to Contact Us</h2>
                </div>
                <div className="section-detail">
                  <p>
                    Digital marketing &amp; advertising approach focuses on
                    capturing the essential
                  </p>
                </div>
                <div className="contact-info">
                  <ul>
                    <li
                      className="contact-inner wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="100ms"
                    >
                      <div className="icon">
                        <i className="icon-location-dot" />
                      </div>
                      <div className="text-box">
                        <span>Location</span>
                        <h3>Duhok, next to RT Bank</h3>
                      </div>
                    </li>
                    <li
                      className="contact-inner wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="300ms"
                    >
                      <div className="icon">
                        <i className="icon-envelope-open-text" />
                      </div>
                      <div className="text-box">
                        <span>Email Address</span>
                        <h3>open.line2024<br />@gmail.com</h3>
                      </div>
                    </li>
                    <li
                      className="contact-inner wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="500ms"
                    >
                      <div className="icon">
                        <i className="icon-phone-volume" />
                      </div>
                      <div className="text-box">
                        <span>Make A Call</span>
                        <h3>+964 751 140 11 95</h3>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 col-md-12 col-sm-12 wow fadeInRight animated"
              data-wow-duration="1500ms"
              data-wow-delay="200ms"
            >
              <div className="contact-page-right">

                <div>
                  <section className="google-map">
                    <div
                      className="wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="100ms"
                    >
                      <iframe className="google-map-one" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d200.39651707541213!2d42.98933082155276!3d36.86299659823634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40088c5176e0aa8d%3A0x1f8f6ac6c368e8af!2sRegion%20Trade%20Bank!5e0!3m2!1sen!2siq!4v1726319597823!5m2!1sen!2siq" style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </section>
                </div>
                {/* <div className="contact-page-form">
                  <div className="contact-page-form-shape-one" />
                  <div className="contact-page-form-shape-two" />
                  <h3 className="contact-page-form-title">
                    Send Us Message
                  </h3>
                  <p className="contact-page-form-text">
                    Questions or you would just like to say hello, contact
                    us.
                  </p>
                  <form
                    onSubmit={handleSubmit}
                    action="send_mail.php"
                    method="POST"
                    className="contact-page__form-box contact-form-validated "
                  >
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="contact-page-input-box">
                          <label htmlFor="full-Name-123">Full Name</label>
                          <input
                            type="text"
                            id="full-Name-123"
                            placeholder="Somaia D. Silva"
                            className="input-contact-text"
                            name="name"
                            required
                            value={formData.name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="contact-page-input-box">
                          <label htmlFor="email-123">Email</label>
                          <input
                            type="email"
                            className="input-contact-text"
                            id="email-123"
                            placeholder="john@gmail.com"
                            name="email"
                            required
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12">
                        <div className="contact-page-input-box">
                          <label htmlFor="textbox">Subject</label>
                          <input
                            type="text"
                            id="textbox"
                            className="input-contact-text"
                            name='subject'
                            placeholder="I would like to discussed"
                            required
                            value={formData.subject}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12">
                        <div className="contact-page-input-box">
                          <label htmlFor="message-123">Message</label>
                          <textarea
                            name="message"
                            className="input-contact-text"
                            id="message-123"
                            placeholder="write message"
                            required
                            defaultValue={""}
                            value={formData.message}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12">
                        <div className="contact-page-input-box">
                          <div className="theme-btn">
                            <button type="submit" role='submit' className="main-btn thm-btn">
                              Send Us Message
                              <i className="icon-right-arrow" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactPage
