import React from "react";
import { Link } from "react-router-dom";
import BlogfourImg1 from "../../assets/images/blog/blog-four-img-1.jpg"
import BlogfourImg2 from "../../assets/images/blog/blog-four-img-2.jpg"
import BlogfourImg3 from "../../assets/images/blog/blog-four-img-3.jpg"

function BlogFour() {
  return (
    <div>
      <section className="blog-four">
        <div className="container">
          <div className="blog-four-title-box">
            <div
              className="section-four-title text-center wow fadeInUp animated"
              data-wow-duration="1500ms"
              data-wow-delay="100ms"
            >
              <div className="section-four-title-title">
                <h2>
                  Browse Our{" "}
                  <span className="section-four-title-title-inner">Articles</span>{" "}
                  On SEO Camping marketing and growth
                </h2>
              </div>
            </div>
            <div
              className="section-four-details text-center wow fadeInUp animated"
              data-wow-duration="1500ms"
              data-wow-delay="300ms"
            >
              <p>
                Digital marketing &amp; advertising approach focuses on capturing
                the essential
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="blog-four-single">
                <div
                  className="blog-four-img-box wow fadeInUp animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="100ms"
                >
                  <img
                    src={BlogfourImg1}
                    alt="blog-four-img-1"
                  />
                </div>
                <div
                  className="blog-four-content-box wow fadeInUp animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <div className="blog-four-date">
                    <p>September 20, 2023</p>
                    <Link to="/blog">SEO Camping</Link>
                  </div>
                  <div className="blog-four-title">
                    <h4>
                      <Link to="/blog-details">
                        Achieving Fashion Elegan Design Runway to Real Life
                      </Link>
                    </h4>
                  </div>
                  <div className="blog-four-user-btn">
                    <Link to="/blog" className="read-more-btn">
                      Read More <i className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="blog-four-single">
                <div
                  className="blog-four-img-box wow fadeInUp animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="200ms"
                >
                  <img
                    src={BlogfourImg2}
                    alt="blog-four-img-1"
                  />
                </div>
                <div
                  className="blog-four-content-box wow fadeInUp animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="400ms"
                >
                  <div className="blog-four-date">
                    <p>September 20, 2023</p>
                    <Link to="/blog">SEO Camping</Link>
                  </div>
                  <div className="blog-four-title">
                    <h4>
                      <Link to="/blog-details">
                        Remote work made easy way better tools.
                      </Link>
                    </h4>
                  </div>
                  <div className="blog-four-user-btn">
                    <Link to="/blog" className="read-more-btn">
                      Read More <i className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="blog-four-single">
                <div
                  className="blog-four-img-box wow fadeInUp animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <img
                    src={BlogfourImg3}
                    alt="blog-four-img-1"
                  />
                </div>
                <div
                  className="blog-four-content-box wow fadeInUp animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="500ms"
                >
                  <div className="blog-four-date">
                    <p>September 20, 2023</p>
                    <Link to="/blog">SEO Camping</Link>
                  </div>
                  <div className="blog-four-title">
                    <h4>
                      <Link to="/blog-details">
                        Achieving Fashion Elegan Design Runway to Real Life
                      </Link>
                    </h4>
                  </div>
                  <div className="blog-four-user-btn">
                    <Link to="/blog" className="read-more-btn">
                      Read More <i className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlogFour;
