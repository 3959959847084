import React, { useState, createRef } from 'react'
import Projectdtls1 from '../../assets/images/shapes/testimonial-three-shape-1.png'
import Shape1 from '../../assets/images/shapes/services-details-content-shape-1.png'
import Banner from '../../assets/images/projects/chinarhome-banner.png'
import HeaderFive from "../HeaderFive/Main";
import Footer from "../FooterOne/Main";
import Breadcrumb from "../Breadcrumb/Main";
import chinarhomeImage1 from '../../assets/images/projects/chinarhome/chinarhome-1.png'
import chinarhomeImage2 from '../../assets/images/projects/chinarhome/chinarhome-2.png'
import chinarhomeImage3 from '../../assets/images/projects/chinarhome/chinarhome-3.png'
import chinarhomeImage4 from '../../assets/images/projects/chinarhome/chinarhome-4.png'
import chinarhomeImage5 from '../../assets/images/projects/chinarhome/chinarhome-5.png'
import chinarhomeImage6 from '../../assets/images/projects/chinarhome/chinarhome-6.png'
import chinarhomeImage7 from '../../assets/images/projects/chinarhome/chinarhome-7.png'
import chinarhomeImage8 from '../../assets/images/projects/chinarhome/chinarhome-8.png'

import poster1 from '../../assets/images/projects/chinarhome/videos/1.jpg'
import video1 from '../../assets/images/projects/chinarhome/videos/1.mp4'
import poster2 from '../../assets/images/projects/chinarhome/videos/2.jpg'
import video2 from '../../assets/images/projects/chinarhome/videos/2.mp4'
import poster3 from '../../assets/images/projects/chinarhome/videos/3.jpg'
import video3 from '../../assets/images/projects/chinarhome/videos/3.mp4'
import poster4 from '../../assets/images/projects/chinarhome/videos/4.jpg'
import video4 from '../../assets/images/projects/chinarhome/videos/4.mp4'

import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'

import "../../assets/css/video-gallery.css"

const images = [
    chinarhomeImage1,
    chinarhomeImage2,
    chinarhomeImage3,
    chinarhomeImage4,
    chinarhomeImage5,
    chinarhomeImage6,
    chinarhomeImage7,
    chinarhomeImage8,
]

const videos = [
    {
        id: 1,
        src: video1,
        poster: poster1,
    },
    {
        id: 2,
        src: video2,
        poster: poster2,
    },
    {
        id: 3,
        src: video3,
        poster: poster3,
    },
    {
        id: 4,
        src: video4,
        poster: poster4,
    },
]

function ChinarHome() {
    return (
        <div>
            <HeaderFive />
            <div className="main-wrapper">
                <div className="data-scroll">
                    <div className="page-wrapper">
                        <div className="page-wrapper-bg-3" />
                        <main className="site-main">
                            <Breadcrumb title="Home" subtitle="Chinar Home" />
                            {/* Single Project Comp */}

                            <div>
                                <section className="project-details">
                                    <div className="services-details-content-shape-1">
                                        <div className="animation_image_zoom">
                                            <div className="services-details-content-shape-1-img">
                                                <img
                                                    src={Projectdtls1}
                                                    alt="testimonial-three-shape-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="services-details-content-shape-2">
                                        <div className="animation_image_zoom">
                                            <div className="services-details-content-shape-2-img">
                                                <img
                                                    src={Shape1}
                                                    alt="services-details-content-shape-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="project-details-box">
                                            <div className="project-details-title-box">
                                                <div className="section-three-title">
                                                    <div
                                                        className="project-details-title-text wow fadeInUp animated"
                                                        data-wow-duration="1500ms"
                                                        data-wow-delay="100ms"
                                                    >
                                                        <span>Social Media</span>
                                                    </div>
                                                    <div
                                                        className="section-three-title-title wow fadeInUp animated"
                                                        data-wow-duration="1500ms"
                                                        data-wow-delay="300ms"
                                                    >
                                                        <h2>Chinar Home</h2>
                                                    </div>
                                                </div>
                                                <div
                                                    className="section-three-details wow fadeInUp animated"
                                                    data-wow-duration="1500ms"
                                                    data-wow-delay="500ms"
                                                >
                                                    <p>
                                                        Content creation, video production and social media management
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="project-details-img wow fadeInUp animated"
                                                data-wow-duration="1500ms"
                                                data-wow-delay="100ms"
                                            >
                                                <img
                                                    src={Banner}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="project-details-info">
                                                <div
                                                    className="project-details-info-item wow fadeInUp animated"
                                                    data-wow-duration="1500ms"
                                                    data-wow-delay="100ms"
                                                >
                                                    <span>CLIENT</span>
                                                    <h3>Chinar Home</h3>
                                                </div>
                                                <div
                                                    className="project-details-info-item wow fadeInUp animated"
                                                    data-wow-duration="1500ms"
                                                    data-wow-delay="300ms"
                                                >
                                                    <span>Category</span>
                                                    <h3>Social Media</h3>
                                                </div>
                                                <div
                                                    className="project-details-info-item wow fadeInUp animated"
                                                    data-wow-duration="1500ms"
                                                    data-wow-delay="500ms"
                                                >
                                                    <span>Location</span>
                                                    <h3>Duhok</h3>
                                                </div>
                                            </div>
                                            {/* results */}
                                            <div
                                                className="project-details-inner-title wow fadeInUp animated"
                                                data-wow-duration="1500ms"
                                                data-wow-delay="100ms"
                                            >
                                                <h2>Results</h2>
                                            </div>
                                            <div class="marquee">
                                                <div class="marquee--inner">
                                                    <span>
                                                        {images.map((image, key) => (
                                                            <div className="orb" key={key}>
                                                                <img
                                                                    src={image}
                                                                    alt="client-one-img-1"
                                                                    className=''
                                                                />
                                                            </div>
                                                        ))}
                                                    </span>
                                                    <span>
                                                        {images.map((image, key) => (
                                                            <div className="orb" key={key}>
                                                                <img
                                                                    src={image}
                                                                    alt="client-one-img-1"
                                                                    className=''
                                                                />
                                                            </div>
                                                        ))}
                                                    </span>
                                                </div>
                                            </div>

                                            {/* Videos */}
                                            <div
                                                className="project-details-inner-title wow fadeInUp animated"
                                                data-wow-duration="1500ms"
                                                data-wow-delay="100ms"
                                            >
                                                <h2>Videos</h2>
                                            </div>
                                            <div className='gallery'>
                                                {videos.map((video) => {
                                                    return (
                                                        <div key={video.id} className="video">
                                                            <div className='video-container' >
                                                                <Video
                                                                    style={{ width: '100%' }}
                                                                    controls={['PlayPause', 'Seek', 'Time', 'Volume', 'FullScreen']}
                                                                    poster={video.poster}
                                                                >
                                                                    <source src={video.src} type="video/mp4" />
                                                                </Video>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </main>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChinarHome
