import React from "react";
import HeaderFive from "../HeaderFive/Main";
import FooterFive from "../FooterOne/Main";
import Breadcrumb from "../Breadcrumb/Main";
// import CaseThree from "../Menservice/CaseThree";
import ServiceOne from "../Menservice/ServiceOne";
import FeaturesThreeSix from "../Menservice/FeaturesThreeSix";
// import TestimonialSix from "../Menservicedtls/TestimonialSix";

function Main() {
  return (
    <div>
      <HeaderFive />
      <div className="main-wrapper">
        <div className="data-scroll">
          <div className="page-wrapper">
            <div className="page-wrapper-bg-2" />
            <div className="page-wrapper-bg-3" />
            <main className="site-main">
              <Breadcrumb title="Home" subtitle="Services" />
              <FeaturesThreeSix />
              <ServiceOne />
              {/* <CaseThree /> */}
              {/* <TestimonialSix /> */}
            </main>
            <FooterFive />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
