import React from "react";
import { Routes, Route } from "react-router-dom";
// import Homeone from "../component/HomeOne/Main";
import Hometwo from "../component/HomeTwo/Main";
import Homethree from "../component/HomeThree/Main";
import Homefour from "../component/HomeFour/Main";
import Homefive from "../component/HomeFive/Main";
import About from "../component/About/Main";
import BlogDetails from "../component/BlogDetails/Main";
import Blog from "../component/Blog/Main";
import Cart from "../component/Cart/Main";
import CheckOut from "../component/CheckOut/Main";
import Contact from "../component/Contact/Main";
import DigitalMarket from "../component/DigitalMarket/Main";
import Faq from "../component/Faq/Main";
import Package from "../component/Package/Main";
import ProjectDetails from "../component/ProjectDetails/Main";
import Projects from "../component/Projects/Main";
import Service from "../component/Service/Main";
import ServicesDetails from "../component/ServicesDetails/Main";
import ShopDetails from "../component/ShopDetails/Main";
import Shop from "../component/Shop/Main";
import TeamMember from "../component/TeamMember/Main";
import VisaLine from "../component/Menprojectdtls/VisLine";
import ChinarHome from "../component/Menprojectdtls/ChinarHome";

function index() {
  return (
    <Routes>
      <Route path="/" element={<Homefive />} />
      {/* <Route path="/hometwo" element={<Hometwo />} />
      <Route path="/homethree" element={<Homethree />} />
      <Route path="/homefour" element={<Homefour />} />
      <Route path="/homefive" element={<Homefive />} /> */}
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects/visa-line" element={<VisaLine />} />
      <Route path="/projects/chinar-home" element={<ChinarHome />} />
      {/* <Route path="/team-member" element={<TeamMember />} /> */}
      {/* <Route path="/package" element={<Package />} /> */}
      {/* <Route path="/faq" element={<Faq />} /> */}
      <Route path="/service" element={<Service />} />
      {/* <Route path="/services-details" element={<ServicesDetails />} /> */}
      {/* <Route path="/digital-market" element={<DigitalMarket />} /> */}
      {/* <Route path="/shop" element={<Shop />} />
      <Route path="/shop-details" element={<ShopDetails />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<CheckOut />} /> */}
      {/* <Route path="/blog" element={<Blog />} /> */}
      {/* <Route path="/blog-details" element={<BlogDetails />} /> */}
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default index;
