import React from 'react';
import serviceFourImg1 from "../../assets/images/services/service-four-img-1.jpg";
import serviceFourImg2 from "../../assets/images/services/service-four-img-2.jpg";
import serviceFourImg3 from "../../assets/images/services/service-four-img-3.jpg";

function ServiceFour() {
  return (
    <div>
      <section className="service-four">
        <div className="container">
          <div className="service-four-title-box">
            <div className="section-five-title">
              <div
                className="section-five-title-text text-center wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="100ms"
              >
                <p>Popular Open Line Services</p>
              </div>
              <div
                className="section-five-title-title text-center wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <h2>
                  Grow Your Audience and Engagement with Our Expert Social Media Strategy
                </h2>
              </div>
            </div>
          </div>
          <div className="service-four-info-box">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div
                  className="service-four-single service-four-single-one wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="100ms"
                >
                  <div className="service-four-tag">
                    <h6>01</h6>
                  </div>
                  <div className="service-four-single-img-box">
                    <img
                      src={serviceFourImg1}
                      alt="service-four-img-1"
                    />
                  </div>
                  <div className="service-four-title">
                    <h4>Content Creation & Strategy</h4>
                  </div>
                  <div className="service-four-text">
                    <p>
                      We create engaging, brand-specific content to captivate your audience and drive results.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div
                  className="service-four-single service-four-single-two wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <div className="service-four-tag">
                    <h6>02</h6>
                  </div>
                  <div className="service-four-single-img-box">
                    <img
                      src={serviceFourImg2}
                      alt="service-four-img-2"
                    />
                  </div>
                  <div className="service-four-title">
                    <h4>Social Media Management</h4>
                  </div>
                  <div className="service-four-text">
                    <p>
                      We manage your accounts, handle posts, and interact with your audience to boost engagement.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div
                  className="service-four-single service-four-single-three wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="500ms"
                >
                  <div className="service-four-tag">
                    <h6>03</h6>
                  </div>
                  <div className="service-four-single-img-box">
                    <img
                      src={serviceFourImg3}
                      alt="service-four-img-3"
                    />
                  </div>
                  <div className="service-four-title">
                    <h4>Analytics & Reporting</h4>
                  </div>
                  <div className="service-four-text">
                    <p>
                      We track performance metrics and optimize strategies to ensure continuous growth.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ServiceFour
