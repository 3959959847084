import React from "react";
import faqBannerImg from "../../assets/images/resources/faq-banner-img.jpg"

function ContactBanner() {
  return (
    <section className="contact-banner">
      <div className="container">
        <div
          className="contact-banner-img wow fadeInUp animated"
          data-wow-duration="1500ms"
          data-wow-delay="500ms"
        >
          <img
            src={faqBannerImg}
            alt="faq-banner-img"
          />
        </div>
      </div>
    </section>
  );
}

export default ContactBanner;
