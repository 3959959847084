import React, { useEffect, useState } from "react";
import CountUp from "react-countup";


function FactTwo() {

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsInitialized(true);
  }, []);


  return (
    <div>
      <section className="fact-two">
        <div className="container">
          <div className="fact-two-detail-box">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                <div
                  className="fact-two-single wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="700ms"
                >
                  <div className="fact-two-single-top-icon fact-two-single-top-icon-one">
                    <i className="icon-layers1" />
                  </div>
                  <div className="fact-two-single-title">
                    <h2>
                      <span>
                        <CountUp
                          start={0}
                          end={5}
                          duration={2}
                        />
                      </span>
                      +
                    </h2>
                  </div>
                  <div className="fact-two-single-detail">
                    <p>Brands Trust Us</p>
                  </div>
                  <div className="fact-two-single-bottom-icon fact-two-single-bottom-icon-one">
                    <i className="icon-Line" />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                <div
                  className="fact-two-single wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="500ms"
                >
                  <div className="fact-two-single-top-icon fact-two-single-top-icon-two">
                    <i className="icon-loyal-customer" />
                  </div>
                  <div className="fact-two-single-title">
                    {isInitialized && (
                      <h2>
                        <span>
                          <CountUp
                            start={0}
                            end={7}
                            duration={2}
                          />
                        </span>
                        +
                      </h2>
                    )}
                  </div>
                  <div className="fact-two-single-detail">
                    <p>Industries Served</p>
                  </div>
                  <div className="fact-two-single-bottom-icon fact-two-single-bottom-icon-two">
                    <i className="icon-Line" />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                <div
                  className="fact-two-single wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <div className="fact-two-single-top-icon fact-two-single-top-icon-three">
                    <i className="icon-positive-thinking1" />
                  </div>
                  <div className="fact-two-single-title">
                    {isInitialized && (
                      <h2>
                        <span>
                          <CountUp
                            start={0}
                            end={100}
                            duration={2}
                          />
                        </span>
                        %
                      </h2>
                    )}
                  </div>
                  <div className="fact-two-single-detail">
                    <p>Client Satisfaction</p>
                  </div>
                  <div className="fact-two-single-bottom-icon fact-two-single-bottom-icon-three">
                    <i className="icon-Line" />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                <div
                  className="fact-two-single wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="100ms"
                >
                  <div className="fact-two-single-top-icon fact-two-single-top-icon-four">
                    <i className="icon-trophy2" />
                  </div>
                  <div className="fact-two-single-title">
                    {isInitialized && (
                      <h2>
                        <span>
                          <CountUp
                            start={0}
                            end={15}
                            duration={2}
                          />
                        </span>
                        +
                      </h2>
                    )}
                  </div>
                  <div className="fact-two-single-detail">
                    <p>Successful Projects Completed</p>
                  </div>
                  <div className="fact-two-single-bottom-icon fact-two-single-bottom-icon-four">
                    <i className="icon-Line" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FactTwo;
