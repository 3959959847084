import React from 'react';
import { Link } from 'react-router-dom';
import Ctaimg1 from '../../assets/images/resources/cta-two-left-img-1.jpg'
import Ctaimg2 from '../../assets/images/resources/cta-two-left-img-2.jpg'

function CtaTwo() {
  return (
    <div>
      <section className="cta-two">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div className="cta-two-right-box">
                <div className="section-two-title">
                  <div className="section-two-title-text wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="100ms">
                    <p>Get Consultations</p>
                  </div>
                  <div className="section-two-title-title wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="300ms" >
                    <h2>
                      Ready to 10x Faster your website's potential &amp;
                      grow sales ?
                    </h2>
                  </div>
                </div>
                <div className="section-two-details wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="500ms">
                  <p>
                    No Credit Card Requirement. We’re 25+ Years Of
                    Experience
                  </p>
                </div>
                <div
                  className="cta-two-right-btn-box wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="700ms"
                >
                  <div className="cta-two-right-btn">
                    <Link to="/service" className="main-btn-two thm-btn">
                      Get Started
                      <i className="icon-right-arrow" />
                    </Link>
                    <Link to="/team-member" className="read-more-btn">
                      Join Our Team
                      <i className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div className="cta-two-left-box">
                <div className="cta-two-left-box-shape-1" />
                <div className="cta-two-left-box-shape-2" />
                <div className="cta-two-left-box-shape-3" />
                <div className="cta-two-left-box-shape-4" />
                <div
                  className="cta-two-left-img-box wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="100ms"
                >
                  <div className="cta-two-left-img-1-shape">
                    <div className="cta-two-left-img-1">
                      <img
                        src={Ctaimg1}
                        alt="cta-two-left-img-1"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="cta-two-left-img-box wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <div className="cta-two-left-img-2-shape">
                    <div className="cta-two-left-img-2">
                      <img
                        src={Ctaimg2}
                        alt="cta-two-left-img-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CtaTwo
