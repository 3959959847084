import React from 'react'
import { Link } from 'react-router-dom';
import aboutFour1 from "../../assets/images/about/about-four-img-1.jpg";
import aboutFour2 from "../../assets/images/about/about-four-img-2.jpg";

function AboutPage() {
  return (
    <div>
      <section className="about-page">
        <div className="about-page-shape" />
        <div className="container">
          <div className="about-four">
            <div className="about-four-top">
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                  <div className="about-four-top-title-box">
                    <p
                      className="wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="100ms"
                    >
                      Welcome to Open Line
                    </p>
                    <h2
                      className="wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="300ms"
                    >
                      Elevate Your Brand with Expert Social Media Strategies
                    </h2>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                  <div className="about-four-top-detail-box">
                    <p
                      className="wow fadeInRight animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="100ms"
                    >
                      Unlock Your Business Potential with Expert Social Media, Software, and Web Solutions
                      We provide comprehensive services, from tailored social media strategies to cutting-edge software and web development, helping your brand grow and succeed online.                    </p>
                    <p
                      className="wow fadeInRight animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="300ms"
                    >
                      Our expertise drives results, whether you're looking to expand your audience, build custom software, or develop powerful web and mobile applications.                    </p>
                    <Link
                      to="/service"
                      className="main-btn thm-btn wow fadeInRight animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="500ms"
                    >
                      Get Started
                      <i className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-four-bottom">
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7">
                  <div
                    className="about-four-bottom-img wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="200ms"
                  >
                    <div className="scroll_item_select_img ">
                      <img
                        src={aboutFour1}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                  <div
                    className="about-four-bottom-img wow fadeInRight animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="200ms"
                  >
                    <div className="scroll_item_select_img">
                      <img
                        src={aboutFour2}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-five">
            <div className="about-four-title-box">
              <div
                className="section-text text-center wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="100ms"
              >
                <p>What We Offer</p>
              </div>
              <div
                className="section-title text-center wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <h2>
                  Boost Leads and Sales with Expert Social Media Strategies
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-1 col-lg-1 col-md-12 col-sm-12" />
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <div
                  className="about-five-single-box text-center wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="200ms"
                >
                  <div className="about-five-single-icon-box icon-box-1">
                    <i className="icon-speed-1" />
                  </div>
                  <div className="about-five-single-title-box">
                    <h3>Empower Brands to Transform Their Digital Presence</h3>
                  </div>
                  <div className="about-five-single-detail-box">
                    <p>
                      We help businesses elevate their marketing with customized strategies for growth and success.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <div
                  className="about-five-single-box text-center wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="200ms"
                >
                  <div className="about-five-single-icon-box icon-box-2">
                    <i className="icon-target-4" />
                  </div>
                  <div className="about-five-single-title-box">
                    <h3>Foster a Workplace that Inspires Growth</h3>
                  </div>
                  <div className="about-five-single-detail-box">
                    <p>
                      Our team-centric approach creates an environment where creativity and professional development thrive.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-1 col-lg-1 col-md-12 col-sm-12" />
              <div className="col-xl-1 col-lg-1 col-md-12 col-sm-12" />
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <div
                  className="about-five-single-box text-center wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="400ms"
                >
                  <div className="about-five-single-icon-box icon-box-3">
                    <i className="icon-target-1-2" />
                  </div>
                  <div className="about-five-single-title-box">
                    <h3>Create Cutting-Edge Mobile Applications</h3>
                  </div>
                  <div className="about-five-single-detail-box">
                    <p>
                      Build seamless, intuitive mobile apps designed to captivate users and enhance business efficiency.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <div
                  className="about-five-single-box text-center wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="400ms"
                >
                  <div className="about-five-single-icon-box icon-box-4">
                    <i className="icon-social-marketing-1" />
                  </div>
                  <div className="about-five-single-title-box">
                    <h3>Develop Engaging and High-Performing Websites</h3>
                  </div>
                  <div className="about-five-single-detail-box">
                    <p>
                      Deliver top-notch web development solutions that enhance user experience and drive business growth.                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-1 col-lg-1 col-md-12 col-sm-12" />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AboutPage
