import React from 'react'
import Service1 from '../../assets/images/resources/features-three-left-img-1.jpg'
import Service2 from '../../assets/images/resources/features-three-left-img-2.jpg'


function FeaturesThreeSix() {
  return (
    <div>
      <section className="features-three features-six">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div className="features-three-left-box">
                <div className="features-three-left-box-shape-1" />
                <div className="features-three-left-box-shape-2" />
                <div className="features-three-left-box-shape-3" />
                <div className="features-three-left-box-shape-4" />
                <div
                  className="features-three-left-img-box wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <div className="features-three-left-img-1">
                    <img
                      src={Service1}
                      alt="features-three-left-img-1"
                    />
                  </div>
                </div>
                <div
                  className="features-three-left-img-box wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="100ms"
                >
                  <div className="features-three-left-img-2">
                    <img
                      src={Service2}
                      alt="features-three-left-img-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div className="features-three-right-box features-six-right-box">
                <div
                  className="section-two-title wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="100ms"
                >
                  <div className="section-two-title-title">
                    <h2>Client Success & Future Growth</h2>
                  </div>
                </div>
                <div
                  className="section-two-details wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <p>
                    We’re committed to helping businesses achieve lasting success with impactful social media strategies tailored to their goals.
                  </p>
                </div>
                <div className="features-three-right-info-box">
                  <div
                    className="features-three-right-info wow fadeInRight animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="500ms"
                  >
                    <div className="features-three-right-info-title">
                      <i className="icon-check" />
                      <h4>Creative Concepts & Content</h4>
                    </div>
                    <div className="features-three-right-info-detail">
                      <p>
                        Generating ideas and crafting engaging content has never been easier—our team turns concepts into compelling campaigns.
                      </p>
                    </div>
                  </div>
                  <div
                    className="features-three-right-info wow fadeInRight animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="700ms"
                  >
                    <div className="features-three-right-info-title">
                      <i className="icon-check" />
                      <h4>Strategy & Execution</h4>
                    </div>
                    <div className="features-three-right-info-detail">
                      <p>
                        From niche markets to broader audiences, we develop strategies that drive growth and engagement, positioning your brand for success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default FeaturesThreeSix
