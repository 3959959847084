import React from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import clientFourImg1 from "../../assets/images/resources/client-four-img-1.png";
import clientFourImg2 from "../../assets/images/resources/client-four-img-2.png";
import clientFourImg3 from "../../assets/images/resources/client-four-img-3.png";
import clientFourImg4 from "../../assets/images/resources/client-four-img-4.png";
import clientFourImg5 from "../../assets/images/resources/client-four-img-5.png";
import clientFourImg6 from "../../assets/images/resources/client-four-img-6.png";
import clientFourImg7 from "../../assets/images/resources/client-four-img-7.png";

const partners = [
  {
    image: clientFourImg1,
  },
  {
    image: clientFourImg2,
  },
  {
    image: clientFourImg3,
  },
]

function PartnerFour() {

  return (
    <div>
      <section className="partener-four">
        <div className="container-fluid">
          <div className="partener-four-title text-center wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="100ms">
            <p>
              Our <span>Clients</span>
            </p>
          </div>
          {/* <Swiper className="swiper-wrapper" data-wow-duration="1500ms" data-wow-delay="300ms" {...settingsPartner} slidesPerView={7} slidestoscroll={1} spaceBetween={20}>
            <SwiperSlide className="partener-four-single">
              <div className="partener-four-img-box">
                <img
                  src={clientFourImg1}
                  alt="client-four-img-1"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide className="partener-four-single">
              <div className="partener-four-img-box">
                <img
                  src={clientFourImg2}
                  alt="client-four-img-2"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide className="partener-four-single">
              <div className="partener-four-img-box">
                <img
                  src={clientFourImg3}
                  alt="client-four-img-3"
                />
              </div>
            </SwiperSlide>
          </Swiper> */}
          <div className="col-flex gap-cl w-full">
            {partners.map((partner) => (
              <div className="partener-four-single flex items-center justify-center">
                <img
                  src={partner.image}
                  alt="client-four-img-1"
                  className="img-cl"
                />
                <div className="partener-four-img-box">
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default PartnerFour;
