import React from 'react';
import processOneBottomImg1 from "../../assets/images/resources/process-one-bottom-img-1.jpg";
import processOneBottomImg2 from "../../assets/images/resources/process-one-bottom-img-2.jpg";

function ProcessThree() {
  return (
    <div>
      <section className="process-three">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="process-three-left">
                <div className="section-five-title">
                  <div
                    className="section-five-title-text wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="100ms"
                  >
                    <p>Working Process</p>
                  </div>
                  <div
                    className="section-five-title-title wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="300ms"
                  >
                    <h2>How we manage your social media</h2>
                  </div>
                </div>
                <div
                  className="process-three-left-img wow fadeInLeft animated animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="500ms"
                >
                  <div className="process-three-left-img-shape-1" />
                  <div className="process-three-left-img-shape-2" />
                  <img
                    src={processOneBottomImg1}
                    alt="process-one-bottom-img-1"
                  />
                  <div className="process-three-left-inner-img">
                    <img
                      src={processOneBottomImg2}
                      alt="process-one-bottom-img-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="process-three-right">
                <div
                  className="process-three-right-info wow fadeInRight animated animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="100ms"
                >
                  <span>01</span>
                  <h5>Brainstorming and Creative ideas &amp; Research</h5>
                </div>
                <div
                  className="process-three-right-info wow fadeInRight animated animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <span>02</span>
                  <h5>Planning and creaintg content</h5>
                </div>
                <div
                  className="process-three-right-info wow fadeInRight animated animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="500ms"
                >
                  <span>03</span>
                  <h5>Building the strategy to boost your audience</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ProcessThree
