import React from "react";
import CtaFour from "../Menhomefive/CtaFour";
import TestimonialFive from "../Menhomefive/TestimonialFive";
import FactTwo from "../Menhomefive/FactTwo";
import ProcessThree from "../Menhomefive/ProcessThree";
import CaseTwo from "../Menhomefive/CaseTwo";
import FeaturesFive from "../Menhomefive/FeaturesFive";
import ServiceFour from "../Menhomefive/ServiceFour";
import PartnerFour from "../Menhomefive/PartnerFour";
import AboutFive from "../Menhomefive/AboutFive";
import BannerFive from "../Menhomefive/BannerFive";
import HeaderFive from "../HeaderFive/Main";
import FooterFive from "../FooterFive/Main";

function Main() {
  return (
    <div>
      <HeaderFive />
      <div className="main-wrapper">
        <div className="data-scroll">
          <div className="page-wrapper page-wrapper-five">
            <main className="site-main">
              <BannerFive />
              <AboutFive />
              <PartnerFour />
              <ServiceFour />
              <FeaturesFive />
              <CaseTwo />
              <ProcessThree />
              <FactTwo />
              {/* <TestimonialFive /> */}
              <CtaFour />
            </main>
            <FooterFive />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
