import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import videoTwoBgImg1 from "../../assets/images/background/video-two-bg-img.jpg";

function VideoTwo() {

  const [show, setshow] = useState(false)

  return (
    <div>
      <section className="video-two">
        <div className="video-two-img-box">
          <div className="scroll_item_select_img">
            <img
              src={videoTwoBgImg1}
              alt=""
            />
          </div>
        </div>
        <div className="container">
          <div className="video-two-btn" onClick={() => setshow(true)}>
            <Link
              to=""
              className="video-popup video-play"
            >
              <i className="icon-play" />
            </Link>
          </div>
        </div>
      </section>

      {show &&
        <div className="YouTubePopUp-Wrap">
          <div className="YouTubePopUp-Content" onClick={() => setshow(false)}>
            <span className="YouTubePopUp-Close" onClick={() => setshow(false)}/>
            <iframe
              src="https://www.youtube.com/embed/rzfmZC3kg3M?autoplay=1"
              allowFullScreen=""
            />
          </div>
        </div>
      }
      
    </div>
  )
}

export default VideoTwo
