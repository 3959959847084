import React, { useState } from 'react';
import aboutImgLeft1 from "../../assets/images/about/about-three-left-img-1.jpg"
import aboutImgLeft2 from "../../assets/images/about/about-three-left-img-2.jpg"

function AboutFive() {
  const [marketingStrategy1, setMarketingStrategy1] = useState(95);
  const [marketingStrategy2, setMarketingStrategy2] = useState(83);

  const handleRangeChange = (event, setValueFunction) => {
    setValueFunction(parseInt(event.target.value));
  };

  return (
    <div>
      <section className="about-three">
        <div className="container">
          <div className="about-three-title-box">
            <div className="section-five-title">
              <div
                className="section-five-title-text text-center wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="100ms"
              >
                <p>Welcome to OPEN LINE Agency</p>
              </div>
              <div
                className="section-five-title-title text-center wow fadeInUp animated"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <h2>
                  Delivering Top-Tier Social Media Strategies for Unstoppable Business Growth
                </h2>
              </div>
            </div>
          </div>
          <div className="about-three-info">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div
                  className="about-three-left-box wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="100ms"
                >
                  <div className="about-three-left-box-shape-1" />
                  <div className="about-three-left-box-shape-2" />
                  <div
                    className="about-three-left-img-box wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="300ms"
                  >
                    <div className="about-three-left-img-1">
                      <img
                        src={aboutImgLeft1}
                        alt="about-three-left-img-1"
                      />
                    </div>
                  </div>
                  <div
                    className="about-three-left-img-box wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="100ms"
                  >
                    <div className="about-three-left-img-2">
                      <img
                        src={aboutImgLeft2}
                        alt="about-three-left-img-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="about-three-right-box">
                  <div
                    className="section-five-title wow fadeInRight animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="100ms"
                  >
                    <div className="section-five-title-title">
                      <h3>
                        Expert Social Media Management & a Professional Team to Grow Your Business
                      </h3>
                    </div>
                  </div>
                  <div
                    className="section-five-details wow fadeInRight animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="300ms"
                  >
                    <p>
                      Our social media management agency is dedicated to helping businesses grow by harnessing the power of expert strategies and a professional team. We focus on creating tailored content, managing platforms, and optimizing engagement to boost your brand's visibility, drive customer interaction, and generate measurable results. Let us handle your social media, so you can focus on what you do best—growing your business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AboutFive;
