import React from "react";
import { Link } from "react-router-dom";
import Blogsingimg1 from '../../assets/images/blog/blog-three-single-img-1.jpg'
import Blogsingimg2 from '../../assets/images/blog/blog-three-single-img-2.jpg'
import Bloguser1 from '../../assets/images/blog/blog-three-user-img-1.png'
import Bloguser2 from '../../assets/images/blog/blog-three-user-img-2.png'


function BlogThree() {
  return (
    <div>
      <section className="blog-three">
        <div className="container">
          <div className="blog-three-title-box">
            <div className="section-three-title">
              <div
                className="section-three-title-text wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="100ms"
              >
                <p>Latest News &amp; Blog</p>
              </div>
              <div
                className="section-three-title-title wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <h2>Explore Marketing Blog</h2>
              </div>
            </div>
            <div
              className="blog-three-btn wow fadeInRight animated"
              data-wow-duration="1500ms"
              data-wow-delay="300ms"
            >
              <Link to="/blog" className="thm-btn transparent-btn">
                View More
                <i className="icon-right-arrow" />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div
                className="blog-three-single-box wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="200ms"
              >
                <div className="blog-three-single-img-box">
                  <img
                    src={Blogsingimg1}
                    alt="blog-three-single-img-1"
                  />
                </div>
                <div className="blog-three-single-detail">
                  <p className="blog-three-date">September 20, 2023</p>
                  <h4 className="blog-three-title">
                    <Link to="/blog-details">
                      Achieving Fashion Elegan Runway to Real Life
                    </Link>
                  </h4>
                  <div className="blog-three-user-box">
                    <div className="blog-three-user">
                      <img
                        src={Bloguser1}
                        alt="blog-three-user-img-1"
                      />
                      <Link to="#">Matthew</Link>
                    </div>
                    <Link to="/blog" className="read-more-btn">
                      Read More
                      <i className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div
                className="blog-three-single-box wow fadeInRight animated"
                data-wow-duration="1500ms"
                data-wow-delay="200ms"
              >
                <div className="blog-three-single-img-box">
                  <img
                    src={Blogsingimg2}
                    alt="blog-three-single-img-2"
                  />
                </div>
                <div className="blog-three-single-detail">
                  <p className="blog-three-date">September 20, 2023</p>
                  <h4 className="blog-three-title">
                    <Link to="/blog-details">
                      Remote work made easy way better tools.
                    </Link>
                  </h4>
                  <div className="blog-three-user-box">
                    <div className="blog-three-user">
                      <img
                        src={Bloguser2}
                        alt="blog-three-user-img-2"
                      />
                      <Link to="#">Feuerstein</Link>
                    </div>
                    <Link to="/blog" className="read-more-btn">
                      Read More
                      <i className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlogThree;
