import React from 'react';
import { Link } from 'react-router-dom';
import Project1 from '../../assets/images/projects/projects-1.jpg'
import Project2 from '../../assets/images/projects/projects-2.jpg'

const projects = [
  {
    title: "Visa Line",
    description: "Social media management",
    image: Project1,
    url: '/projects/visa-line'
  },
  {
    title: "Chinar Home",
    description: "Social media management & Video production",
    image: Project2,
    url: '/projects/chinar-home'
  },
]


function ProjectPage() {
  return (
    <div>
      <section className="projects-page">
        <div className="container">
          <div className="row">

            {projects.map((project, index) => (
              <Link to={project.url} key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="projects-single-box">
                  <div
                    className="projects-single-img wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="100ms"
                  >
                    <img
                      src={project.image}
                      alt={project.title}
                    />
                  </div>
                  <div
                    className="projects-single-detail wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="300ms"
                  >
                    <span>{project.title}</span>
                    <h3>
                      {project.description}
                    </h3>
                  </div>
                </div>
              </Link>
            ))}

          </div>

          {/* <div
            className="projects-view-more-btn text-center wow fadeInUp animated"
            data-wow-duration="1500ms"
            data-wow-delay="300ms"
          >
            <Link to="/projects" className="main-black-btn thm-btn">
              View More Project <i className="icon-right-arrow" />
            </Link>
          </div> */}
        </div>
      </section>
    </div>
  )
}

export default ProjectPage
