import { Carousel } from "@material-tailwind/react";
import caseThreeImg1 from "../../assets/images/resources/case-three-1.png";
import caseThreeImg2 from "../../assets/images/resources/case-three-2.png";

const projects = [
    {
        image: caseThreeImg1,
        service: "Social media management",
        title: "Visa Line",
        description: "Content creation and management of social platforms",
    },
    {
        image: caseThreeImg2,
        service: "Social media management",
        title: "Visa Line",
        description: "Content creation and management of social platforms",
    },
]

function CustomSlider() {

    return (
        <Carousel
            autoplay
            className="rounded-xl"
            navigation={({ setActiveIndex, activeIndex, length }) => (
                <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                    {new Array(length).fill("").map((_, i) => (
                        <span
                            key={i}
                            className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                }`}
                            onClick={() => setActiveIndex(i)}
                        />
                    ))}
                </div>
            )}
        >
            {projects.map((project, index) => (
                <div
                    key={index}
                    className="case-two-single-box wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="100ms"
                >
                    <div className="case-two-single-img-box">
                        <img src={project.image} alt="case-three-1" />
                    </div>
                    <div className="case-two-single-detail-box">
                        <span>{project.service}</span>
                        <h4>{project.title}</h4>
                        <p>{project.description}</p>
                    </div>
                </div>
            ))}
        </Carousel>
    );

};

export default CustomSlider;