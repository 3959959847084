import React from "react";
import HeaderFive from "../HeaderFive/Main";
import Footer from "../FooterOne/Main";
import Breadcrumb from "../Breadcrumb/Main";
import ProjectDetails from "../Menprojectdtls/ProjectDetails";

function Main() {
  return (
    <div>
      <HeaderFive />
      <div className="main-wrapper">
        <div className="data-scroll">
          <div className="page-wrapper">
            <div className="page-wrapper-bg-3" />
            <main className="site-main">
              <Breadcrumb title="Home" subtitle="Project Details" />
              <ProjectDetails />
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
