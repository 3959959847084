import React from 'react';
import { Link } from 'react-router-dom';
import footerLogo from "../../assets/images/resources/footer-logo.png"

function Main() {
  return (
    <div>
      <footer className="site-footer">
        <div className="container">
          <div className="footer-main">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div
                  className="footer-widget footer-widget-about wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="700ms"
                >
                  <div className="footer-widget-about-logo">
                    <Link to="/">
                      <img
                        src={footerLogo}
                        alt="footer-logo"
                      />
                    </Link>
                  </div>
                  <div className="footer-widget-about-detail">
                    <p>
                      Follow Us on Instagram for the Latest Updates
                    </p>
                  </div>
                  <ul className="footer-widget-social footer-widget-social-five">
                    {/* <li>
                      <Link to="#">
                        <i className="icon-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icon-twitter" />
                      </Link>
                    </li> */}
                    <li>
                      <Link to="https://www.instagram.com/openline.co?igsh=bTA3dm8wb2pnZWVs">
                        <i className="icon-instagram" />
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#">
                        <i className="icon-linkedin-in" />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6 col-sm-12">
                <div
                  className="footer-widget footer-widget-link wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="500ms"
                >
                  <h4 className="footer-widget-title">Quick Link</h4>
                  <ul className="footer-widget-link-details">
                    <li>
                      <Link to="/service">Popular Services</Link>
                    </li>
                    <li>
                      <Link to="/team-member">Team Member</Link>
                    </li>
                    <li>
                      <Link to="/contact">Privacy &amp; Setting</Link>
                    </li>
                    <li>
                      <Link to="/package">Pricing Package</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div
                  className="footer-widget footer-widget-address wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <h4 className="footer-widget-title">Get In Touch</h4>
                  <ul className="footer-widget-address-details">
                    <li>
                      <i className="icon-location" />
                      <p>Duhok, next to RT Bank</p>
                    </li>
                    <li>
                      <i className="icon-sms-box" />
                      <Link to="/mailto:open.line2024@gmail.com">open.line2024@gmail.com</Link>
                    </li>
                    <li>
                      <i className="icon-call-icon" />
                      <Link
                        to="/tel:+9647511401195"
                        className="footer-widget-number"
                      >
                        +964 751 140 11 95
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6 col-sm-12">
                <div
                  className="footer-widget footer-widget-newsletter wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="100ms"
                >
                  <h4 className="footer-widget-title">Newsletter</h4>
                  <p className="footer-widget-newsletter-detail">
                    Get more update to join Us
                  </p>
                  <form action="#" className="newsletter-email-box">
                    <input
                      type="email"
                      id="email"
                      name="EMAIL"
                      placeholder="Email Address"
                    />
                    <button className="newsletter-send-box newsletter-send-box">
                      <i className="icon-right-arrow" />
                    </button>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
          <div className="footer-bottom">
            <div
              className="footer-bottom-inner wow fadeInUp animated"
              data-wow-duration="1500ms"
              data-wow-delay="100ms"
            >
              {/* <ul className="footer-bottom-inner-item">
                <li>
                  <Link to="/faq">FAQs</Link>
                </li>
                <li>
                  <Link to="/about">Company</Link>
                </li>
                <li>
                  <Link to="/contact">Privacy</Link>
                </li>
              </ul> */}
              <div className="copyright">
                <p>
                  © 2024 · <Link to="/">Open Line</Link>, All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Main
