import React, { useState } from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import featureFiveImg from "../../assets/images/resources/features-five-img.jpg";
import experienceImg1 from "../../assets/images/resources/experience-img-1.jpg";
import experienceImg2 from "../../assets/images/resources/experience-img-2.jpg";
import experienceImg3 from "../../assets/images/resources/experience-img-3.jpg";
// import experienceImg4 from "../../assets/images/resources/experience-img-4.jpg";
// import experienceImg5 from "../../assets/images/resources/experience-img-5.jpg";

const Featureslider = {
  loop: true,
  autoplayspeed: 2000,
  slidesPerView: 5,
  slidestoscroll: 5,
  autoplay: true,

  easing: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesPerView: 2,
        slidestoscroll: 3,
        arrows: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesPerView: 2,
        slidestoscroll: 3,

      },
    },
    {
      breakpoint: 740,
      settings: {
        slidesPerView: 1,
        slidestoscroll: 3,

      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesPerView: 1,
        slidestoscroll: 3,

      },
    },
  ],
};


function FeaturesFive() {

  const [show, setshow] = useState(false)

  return (
    <div>
      <section className="features-five">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div className="features-five-left">
                <div className="section-five-title">
                  <div
                    className="section-five-title-title wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="100ms"
                  >
                    <h2>
                      Boost Conversions with Targeted Social Media Campaigns and Organic Growth Strategies</h2>
                  </div>
                  <div
                    className="section-five-details wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="300ms"
                  >
                    <p>Driven by Fresh Ideas and the Latest Trends</p>
                  </div>
                </div>
                <div
                  className="features-five-right-btn-box wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="500ms" onClick={() => setshow(true)}
                >
                  <div className="features-five-right-btn">
                    <Link to="/projects" className="main-btn-five thm-btn">
                      Learn More
                      <i className="icon-right-arrow" />
                    </Link>
                  </div>
                  {/* <div className="features-five-right-video" >
                    <Link
                      to="#"
                      className="video-popup video-play"
                    >
                      <i className="icon-play" />
                    </Link>
                    <Link
                      to="#"
                      className="video-popup features-five-right-link"
                    >
                      Play Video
                    </Link>
                  </div> */}
                </div>
                <div
                  className="features-five-left-clients-box wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="700ms"
                >
                  <ul className="features-five-left-clients-list">
                    <li>
                      <img src={experienceImg1} alt="experience-img-1" />
                    </li>
                    <li>
                      <img src={experienceImg2} alt="experience-img-2" />
                    </li>
                    <li>
                      <img src={experienceImg3} alt="experience-img-3" />
                    </li>
                    {/* <li>
                      <img src={experienceImg4} alt="experience-img-4" />
                    </li>
                    <li>
                      <img src={experienceImg5} alt="experience-img-4" />
                    </li> */}
                  </ul>
                  <p>Trusted by a Growing Number of Satisfied Clients</p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div
                className="features-five-right wow fadeInRight animated"
                data-wow-duration="1500ms"
                data-wow-delay="100ms"
              >
                <div className="features-five-right-shape-1" />
                <div className="features-five-right-shape-2" />
                <div className="features-five-right-shape-3" />
                <div className="features-five-right-img-box">
                  <div className="scroll_item_select_img">
                    <img src={featureFiveImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quoye-two">
          <div className="container-fluid">
            {/* <Swiper className="swiper-wrapper" slidesPerView={4} autoplay={true} autoplayspeed={1000} {...Featureslider}>
              <SwiperSlide className="quoye-two-details-item">
                <i className="icon-seo3" />
                <h4>SEO Agency</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-two-details-item">
                <i className="icon-globe-1-1" />
                <h4>Web Optimization</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-two-details-item">
                <i className="icon-megaphone1" />
                <h4>Marketing Strategy</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-two-details-item">
                <i className="icon-curve1" />
                <h4>Digital Products Design</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-two-details-item">
                <i className="icon-idea-2-1" />
                <h4>Keywords Research</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-two-details-item">
                <i className="icon-seo3" />
                <h4>SEO Agency</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-two-details-item">
                <i className="icon-globe-1-1" />
                <h4>Web Optimization</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-two-details-item">
                <i className="icon-megaphone1" />
                <h4>Marketing Strategy</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-two-details-item">
                <i className="icon-curve1" />
                <h4>Digital Products Design</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-two-details-item">
                <i className="icon-idea-2-1" />
                <h4>Keywords Research</h4>
              </SwiperSlide>
            </Swiper> */}
          </div>
        </div>
      </section>

      {show &&
        <div className="YouTubePopUp-Wrap">
          <div className="YouTubePopUp-Content" onClick={() => setshow(false)}>
            <span className="YouTubePopUp-Close" onClick={() => setshow(false)} />
            <iframe
              src="https://www.youtube.com/embed/rzfmZC3kg3M?autoplay=1"
              allowFullScreen=""
            />
          </div>
        </div>
      }

    </div>
  );
}

export default FeaturesFive;
