import React, { useState } from "react";
import { Link } from "react-router-dom";
import sectionFiveTitleIcon from "../../assets/images/shapes/section-five-title-text-icon.png";
import bussinessOneGrowTitle from "../../assets/images/shapes/business-grow-one-left-img-four.png";
import bussinessleftFive from "../../assets/images/shapes/business-grow-one-left-img-five-shape.png";
import bannnerfiveLeft from "../../assets/images/resources/banner-five-left-img.jpg";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

const settingsagent = {
  items: 3,
  margin: 30,
  loop: true,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesperview: 1,
        slidestoscroll: 1,
        margin: 30,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesperview: 1,
        slidestoscroll: 1,
        margin: 30,
      },
    },
    {
      breakpoint: 799,
      settings: {
        slidesperview: 1,
        slidestoscroll: 1,
        margin: 30,
      },
    },
  ],
};

function BannerFive() {

  const [show, setshow] = useState(false)

  return (
    <div>
      <section className="banner-five">
        <div className="banner-five-shape-1" />
        <div className="banner-five-shape-2" />
        <div className="container">
          <div className="banner-five-info-box">
            <div className="row">
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="banner-five-left">
                  <div className="section-five-title">
                    <div
                      className="section-five-title-text wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="100ms"
                    >
                      <img
                        src={sectionFiveTitleIcon}
                        alt="section-five-title-text-icon"
                      />
                      <p>Socail Media Management Agency</p>
                    </div>
                    <div
                      className="section-five-title-title wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="300ms"
                    >
                      <h1>Let Our Agency Elevate Your Brand Today!</h1>
                    </div>
                  </div>
                  <div className="banner-five-right-btn-box">
                    <div
                      className="banner-five-right-btn wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="500ms"
                    >
                      <Link to="/service" className="main-btn-five thm-btn">
                        Explore Our Services
                        <i className="icon-right-arrow" />
                      </Link>
                    </div>
                    {/* <div
                      className="banner-five-right-video wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="700ms"
                      onClick={() => setshow(true)} >
                      <Link
                        to=""
                        className="video-popup video-play"
                      >
                        <i className="icon-play" />
                      </Link>
                      <Link to="/service" className="banner-five-right-link">
                        How IT Works
                      </Link>
                    </div> */}
                  </div>

                </div>
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="banner-five-right">
                  <div
                    className="banner-five-right-inner-img wow fadeInRight animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="100ms"
                  >
                    <img
                      src={bussinessOneGrowTitle}
                      alt="business-grow-one-left-img-four"
                    />
                    <img
                      src={bussinessleftFive}
                      alt="business-grow-one-left-img-five-shape"
                    />
                  </div>
                  <div
                    className="banner-five-right-img-box wow fadeInRight animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="300ms"
                  >
                    <div className="scroll_item_select_img">
                      <img src={bannnerfiveLeft} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quoye-one">
          <div className="quoye-one-shape" />
          <div className="container-fluid">
            <Swiper className="swiper-wrapper" {...settingsagent} slidesPerView={4} slidestoscroll={1} autoplay={true} spaceBetween={10}>
              <SwiperSlide className="quoye-one-details-item slider-item-custom">
                {/* <i className="icon-seo3" /> */}
                <h4>Social Media Marketing</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-one-details-item slider-item-custom">
                {/* <i className="icon-globe-1-1" /> */}
                <h4>Content Creation</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-one-details-item slider-item-custom">
                {/* <i className="icon-megaphone1" /> */}
                <h4>Brand Strategy</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-one-details-item slider-item-custom">
                {/* <i className="icon-curve1" /> */}
                <h4>Creative Campaigns</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-one-details-item slider-item-custom">
                {/* <i className="icon-idea-2-1" /> */}
                <h4>Audience Engagement</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-one-details-item slider-item-custom">
                {/* <i className="icon-seo3" /> */}
                <h4>Analytics & Reporting</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-one-details-item slider-item-custom">
                {/* <i className="icon-globe-1-1" /> */}
                <h4>Platform Management</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-one-details-item slider-item-custom">
                {/* <i className="icon-megaphone1" /> */}
                <h4>Targeted Ads</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-one-details-item slider-item-custom">
                {/* <i className="icon-curve1" /> */}
                <h4>Creative Design</h4>
              </SwiperSlide>
              <SwiperSlide className="quoye-one-details-item slider-item-custom">
                {/* <i className="icon-idea-2-1" /> */}
                <h4>Market Research</h4>
              </SwiperSlide>
            </Swiper>

          </div>
        </div>
      </section>

      {show &&
        <div className="YouTubePopUp-Wrap">
          <div className="YouTubePopUp-Content" onClick={() => setshow(false)}>
            <span className="YouTubePopUp-Close" onClick={() => setshow(false)} />
            <iframe
              src="https://www.youtube.com/embed/rzfmZC3kg3M?autoplay=1"
              allowFullScreen=""
            />
          </div>
        </div>
      }
    </div>
  );
}

export default BannerFive;
