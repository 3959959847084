import React from 'react'
import featuresImage from '../../assets/images/resources/features-two-right-img-1.jpg';

function FeaturesTwo() {
  return (
    <div>
      <section className="features-two">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12">
              <div className="features-two-left-box">
                <div className="section-two-title">
                  <div
                    className="section-two-title-text wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="100ms"
                  >
                    <p>SEO Solutions Agency</p>
                  </div>
                  <div
                    className="section-two-title-title wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="300ms"
                  >
                    <h2>Increasing Sales &amp; grow business by SEO</h2>
                  </div>
                </div>
                <div
                  className="section-two-details wow fadeInLeft animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="500ms"
                >
                  <p>
                    Sed ut perspiciatis unde omnis iste natus voluptatem
                    accusantium doloremque laudantium, totam rem aperiam
                    eaque
                  </p>
                </div>
                <div className="features-two-left-info-box">
                  <div
                    className="features-two-left-info wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="1100ms"
                  >
                    <div className="features-two-left-info-icon">
                      <i className="icon-cloud-computing" />
                    </div>
                    <div className="features-two-left-info-title">
                      <h5>Modern AI Technology</h5>
                    </div>
                    <div className="features-two-left-info-detail">
                      <p>Sed perspiciatis omniste natusto voluta usan</p>
                    </div>
                  </div>
                  <div
                    className="features-two-left-info wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="900ms"
                  >
                    <div className="features-two-left-info-icon">
                      <i className="icon-seo" />
                    </div>
                    <div className="features-two-left-info-title">
                      <h5>Growth SEO Strategy</h5>
                    </div>
                    <div className="features-two-left-info-detail">
                      <p>Sed perspiciatis omniste natusto voluta usan</p>
                    </div>
                  </div>
                  <div
                    className="features-two-left-info wow fadeInLeft animated"
                    data-wow-duration="1500ms"
                    data-wow-delay="700ms"
                  >
                    <div className="features-two-left-info-icon">
                      <i className="icon-seo2" />
                    </div>
                    <div className="features-two-left-info-title">
                      <h5>Optimize Digital Audit</h5>
                    </div>
                    <div className="features-two-left-info-detail">
                      <p>Sed perspiciatis omniste natusto voluta usan</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
              <div className="features-two-right-box">
                <div
                  className="features-two-right-box-shape wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                />
                <div
                  className="features-two-right-img-box wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="100ms"
                >
                  <div className="features-two-right-img">
                    <div className="scroll_item_select_img">
                      <img src={featuresImage} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default FeaturesTwo
