import React from 'react'
import { Link } from 'react-router-dom';
import JoinsOneImg1 from "../../assets/images/resources/joinus-one-left-img-1.jpg";
import JoinsOneImg2 from "../../assets/images/resources/joinus-one-left-img-2.jpg";

function JoinusOne() {
  return (
    <div>
      <section className="joinus-one joinus-three">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="joinus-one-left-box">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <div
                      className="joinus-one-left-img-one wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="500ms"
                    >
                      <img
                        src={JoinsOneImg1}
                        alt="joinus-one-left-img-1"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <div
                      className="joinus-one-icon-box wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="100ms"
                    >
                      <i className="icon-arrow-right-up" />
                    </div>
                    <div
                      className="joinus-one-left-img-two wow fadeInLeft animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="300ms"
                    >
                      <img
                        src={JoinsOneImg2}
                        alt="joinus-one-left-img-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="joinus-one-right-box">
                <div
                  className="section-title wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="100ms"
                >
                  <h2>
                    <span className="section-title-shape-2">
                      Boost your
                    </span>{" "}
                    marketing process with us
                  </h2>
                </div>
                <div
                  className="section-details wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <p>
                    Sed perspiciatis unde omnis natus voluptatem accusantie
                    doloremque laudantium totamrec aperiam eaque inventore
                    veritatis et quasi architecto beatae vitae dicta
                  </p>
                </div>
                <div
                  className="joinus-one-list-box wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="500ms"
                >
                  <div className="joinus-one-list-detali">
                    <i className="icon-check" />
                    <p>Marketing Strategy &amp; SEO Camping</p>
                  </div>
                  <div className="joinus-one-list-detali">
                    <i className="icon-check" />
                    <p>Best Customers Experience</p>
                  </div>
                </div>
                <div
                  className="joinus-one-btn-box wow fadeInRight animated"
                  data-wow-duration="1500ms"
                  data-wow-delay="700ms"
                >
                  <Link to="/contact" className="main-black-btn thm-btn">
                    Learn More
                    <i className="icon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default JoinusOne
